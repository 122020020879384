<template>
    <b-sidebar
     id="widget-form-sidebar"
     :visible="isWidgetFormSidebarActive"
     bg-variant="white"
     sidebar-class="sidebar-lg"
     shadow
     backdrop
     no-header
     right
     @shown="getData(widgetData)"
     @hidden="resetForm"
     @change="(val) => $emit('update:is-widget-form-sidebar-active', val)"
   >

   <template #default="{ hide }"> 
       <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
           <h5 class="mb-0">
               Widget  Form
           </h5>
   
           <feather-icon
               class="ml-1 cursor-pointer"
               icon="XIcon"
               size="16"
               @click="hide"
           />
       </div>
       <validation-observer
         #default="{ handleSubmit }"
         ref="refFormObserver"
       >
         <b-form
           class="p-2"
           @submit.prevent="handleSubmit(onSubmit)"
           @reset.prevent="resetForm"
         >
          <b-tabs v-model="tabIndex">
            <b-tab title="Content">
              <validation-provider
             #default="validationContext"
             name="Title"
             rules="required"
           >
           
             <b-form-group
               label="Title"
               label-for="title"
             >
               <b-form-input
                 id="title"
                 v-model="Data.title"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Title"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
           <validation-provider
             #default="validationContext"
             name="uri"
             rules="min:0"
           >
             <b-form-group
               label="Uri"
               label-for="uri"
             >
               <b-form-input
                 id="uri"
                 v-model="Data.uri"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="uri"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
           <validation-provider
             #default="validationContext"
             name="short_description"
             rules="min:0"
           >
             <b-form-group
               label="Short Description"
               label-for="short_description"
             >
               <b-form-textarea
                 id="short_description"
                 v-model="Data.short_description"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Short Description"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
          
           
          
         
                


           <div class="d-flex mt-2">
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               type="submit"
             >
               Submit
             </b-button>
             <b-button
               v-ripple.400="'rgba(186, 191, 199, 0.15)'"
               type="button"
               variant="outline-secondary"
               @click="hide"
             >
               Cancel
             </b-button>
           </div>

            </b-tab>
            <b-tab title="Images" v-if="widgetData.id" >
                  
                  <upload-form  v-if="tabIndex == 1" :widget-data.sync="widgetData" />
            </b-tab>
          </b-tabs>
           
           
         </b-form>
         
         
       </validation-observer>
   </template>
   </b-sidebar>
</template>
<script>
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile
 } from 'bootstrap-vue'
 import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { ref } from '@vue/composition-api'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import Ripple from 'vue-ripple-directive'
 import vSelect from 'vue-select'
 import countries from '@/@fake-db/data/other/countries'
 import store from '@/store'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'
 import UploadForm from './UploadForm.vue'


import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
export default {
   components: {
     BSidebar,
     BForm,
     BFormGroup,
     BFormInput,
     BFormTextarea,
     BFormCheckbox,
     BFormInvalidFeedback,
     BButton,
     BTabs, BTab,
     BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,
     vSelect,
     quillEditor,
     UploadForm,
     // Form Validation
     ValidationProvider,
     ValidationObserver,
   },
   directives: {
     Ripple,
   },
   model: {
     prop: 'isWidgetFormSidebarActive',
     event: 'update:is-widget-form-sidebar-active',
   },
   props: {
     isWidgetFormSidebarActive: {
       type: Boolean,
       required: true,
     },
    
     widgetData: {
       type: Object,
       required: false,
     },
   },
   data(){
      return {
        snowOption: {
          theme: 'snow',
        },
      }
   },
   setup(props, { emit }) {
    
       const toast = useToast()
       const tabIndex = ref(0);
       const featuredImage = ref({});
       const userStorage = JSON.parse(localStorage.getItem('userData'));
       const blankData = {
         
       
           created_by:userStorage.id,
           created_at:null,
           title: '',
           uri:'',
           short_description:'',
           area_id:'',
          
         
  
       }
       const Data = ref(JSON.parse(JSON.stringify(blankData)))
      
       const resetData = () => {
           Data.value = JSON.parse(JSON.stringify(blankData))
       }
   
       const getData = (data)=>{
        
        tabIndex.value = 0;
           if(data.id){
                
               
               Data.value  = JSON.parse(JSON.stringify(data));
           }else{

            blankData.area_id = data.area_id;
            console.log('blankData',blankData);
            Data.value = JSON.parse(JSON.stringify(blankData))
           }
       }
       const onSubmit = () => {
        
           if(Data.value.id){
             
               store.dispatch('app-widget/updateData', Data.value)
               .then((res) => {
                   
                   if(res.data.success){
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   emit('refetch-data')
                   emit('update:is-widget-form-sidebar-active', false)
                   }else{
                   
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               
               })
           }else{
               store.dispatch('app-widget/addData', Data.value)
               .then((res) => {
                   
                   if(res.data.success){
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   emit('refetch-data')
                   emit('update:is-widget-form-sidebar-active', false)
                   }else{
                   
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               
               })
           }

       }

       
        const {
       refFormObserver,
       getValidationState,
       resetForm,
     } = formValidation(resetData)
     //upload
    
     return {
       Data,
       onSubmit,
      
       refFormObserver,
       getValidationState,
       resetForm,
       getData,
       tabIndex
      
       
     }
  
   }
   
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>